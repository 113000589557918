<template>
  <div class="user-info">
    <a-icon type="user" />
    <span style="padding-left: 0.4vw">{{ user.name }}</span>
    <span class="divider">|</span>
    <img src="../../assets/exit.png" alt="" @click="logout" />
    <span @click="logout">退出</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  mounted() {
    const str = window.localStorage.getItem("user");
    if (str) {
      this.user = JSON.parse(str);
    }
  },
  methods: {
    logout() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.user-info {
  display: flex;
  align-items: center;
  font-size: 0.5vw;
  cursor: pointer;

  .divider {
    margin: 0 0.4vw;
  }

  img {
    width: 0.4vw;
    margin-right: 0.2vw;
    display: flex;
  }
}
</style>