<template>
  <div class="layout">
    <div class="header">
      <div class="header-bg">
        <div class="menu">
          <div v-for="item in menu.filter((item, index) => index < 3)" :key="item.id">
            <div v-if="item.children && item.children.length > 0">
              <a-dropdown overlayClassName="dropdown">
                <a :class="isActivePath(item.path) ? 'menu-item active' : 'menu-item'
                  ">{{ item.name }}</a>

                <div slot="overlay" style="background-color: #000; padding: 8px 16px">
                  <div v-for="element in item.children" :key="element.id">
                    <router-link :to="element.path" style="line-height: 2em">{{
                      element.name
                    }}</router-link>
                  </div>
                </div>
              </a-dropdown>
            </div>
            <router-link v-else :to="item.path" :class="isActivePath(item.path) ? 'menu-item active' : 'menu-item'
              ">{{ item.name }}</router-link>
          </div>
        </div>

        <div class="app-name">淮安清江浦区智慧水务系统</div>

        <div class="menu">
          <div v-for="item in menu.filter((item, index) => index > 2)" :key="item.id">
            <div v-if="item.children && item.children.length > 0">
              <a-dropdown overlayClassName="dropdown">
                <a :class="isActivePath(item.path) ? 'menu-item active' : 'menu-item'
                  ">{{ item.name }}</a>

                <div slot="overlay" style="background-color: #000; padding: 8px 16px">
                  <div v-for="element in item.children" :key="element.id">
                    <router-link :to="element.path" style="line-height: 2em">{{
                      element.name
                    }}</router-link>
                  </div>
                </div>
              </a-dropdown>
            </div>
            <router-link v-else :to="item.path" :class="isActivePath(item.path) ? 'menu-item active' : 'menu-item'
              ">{{ item.name }}</router-link>
          </div>
        </div>

        <div class="extra">
          <Weather />
          <Message />
          <UserInfo />
        </div>
      </div>
    </div>
    <!--20240219 张弘 ：左右图片会挡住操作面板上的按钮，先注释掉了-->
    <!--
    <div class="left-bg">
      <img class="left-bg-image" src="@/assets/left-panel-bg.png" alt="" />
    </div>
    <div class="right-bg">
      <img class="right-bg-image" src="@/assets/right-panel-bg.png" alt="" />
    </div>
  -->
    <div class="body">
      <router-view></router-view>
    </div>

    <!-- <MapTools v-if="curPath === '/'" /> -->
  </div>
</template>
<script>
// import MapTools from "@/components/map-tools";
import Message from "./components/message.vue";
import UserInfo from "./components/user-info.vue";
import Weather from "./components/weather.vue";

import { fetchUserPermissionList } from "@/api/login";
import { fetchList } from "@/api/setting/menu";

import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    // MapTools,
    UserInfo,
    Weather,
    Message,
  },

  data() {
    return {
      menu: [],

      allMenu: [],
      menuByTree: [],

      curPath: this.$route.path,
    };
  },

  watch: {
    "$route.path"(newV) {
      this.curPath = newV;
    },
  },

  mounted() {
    const user = JSON.parse(window.localStorage.getItem("user"));

    fetchUserPermissionList({ id: user.id }).then((res) => {
      if (res && Array.isArray(res.permission)) {
        this.permission = res.permission;
        this.setPermission(this.permission);

        fetchList().then((res) => {
          console.log("res", res);
          if (Array.isArray(res)) {
            this.allMenu = res;
            this.getUserMenu(this.allMenu, this.permission);
          }
        });
      }
    });

    this.getDataDict();
  },

  methods: {
    ...mapMutations("auth", ["setPermission", "setMenu"]),
    ...mapActions("setting", ["getDataDict"]),

    isActivePath(path) {
      if (path === "/") {
        return this.curPath === path;
      } else {
        return this.curPath.includes(path);
      }
    },

    getUserMenu(menu, permission) {
      // 给角色绑定权限列表，不绑定菜单列表，在当前角色下注册了用户后，也就标明了该用户有了权限表，登陆后拿着所有的菜单表去跟权限交叉匹配。
      const resultList = [];

      menu.forEach((item) => {
        const result = permission.find(
          (element) => element.code === item.permission
        );
        if (result) {
          resultList.push(item);
        }
      });

      const result = resultList
        .filter((item) => item.status === 1 && item.pid === "0")
        .sort((x, y) => {
          if (x.sort < y.sort) {
            return -1;
          } else if (x.sort > y.sort) {
            return 1;
          } else {
            return 0;
          }
        }); // 过滤出状态正常的菜单

      result.forEach((item) => {
        item.children = resultList.filter((element) => {
          return element.pid === item.id;
        });
      });

      this.setMenu(result); //存到vuex中
      console.log("result", result);
      this.menu = result;
    },
  },
};
</script>
<style lang="less" scoped>
.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.left-bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  padding-left: 1vw;
  padding-top: 1vh;
  box-sizing: border-box;
  background: linear-gradient(to right,
      #000 0%,
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0));
  z-index: 110;
}

.left-bg-image {
  height: 98vh;
}

.right-bg {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  padding-right: 1vw;
  padding-top: 1vh;
  box-sizing: border-box;
  background: linear-gradient(to left,
      #000 0%,
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0));
  z-index: 110;
}

.right-bg-image {
  height: 98vh;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: linear-gradient(to bottom,
      #000 0%,
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0));
}

.header-bg {
  height: 12vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 4vw;
  color: #fff;
  background-image: url("../assets/header-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .app-name {
    font-size: 2vw;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-family: YouSheBiaoTiHei;
    color: #fff;
    letter-spacing: 1px;
    background-image: linear-gradient(180deg,
        #ffffff 0%,
        #ffffff 30%,
        #57a1e3 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .menu {
    display: flex;
    align-items: center;
    margin-top: 4vh;
    gap: 1vw;

    .menu-item {
      background-image: url(../assets/menu-bg.png);
      background-size: contain;
      background-repeat: no-repeat;
      color: #fff;
      display: inline-block;
      width: 6vw;
      height: 5vh;
      line-height: 4.5vh;
      font-size: 0.7vw;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
    }

    .active {
      background-image: url(../assets/menu-bg-active.png);
      color: #fff;
    }
  }

  .extra {
    position: absolute;
    top: 0;
    right: 1vw;
    display: flex;
    align-items: center;
  }
}

.body {
  flex: 1;
  position: relative;
  z-index: 99;
}

.dropdown {
  box-shadow: 0px 4px 5px 1px rgb(0 0 0 / 16%);
  border-radius: 0px 0px 4px 4px;
}
</style>
