


<template>
    <div class="button" @click="open">
        <a-icon type="message" title="消息中心" />
    </div>
</template>
  
<script>

export default {

    methods: {
        open() {
            this.$router.push("/message")
        }
    }
};
</script>
  
<style lang="less" scoped>
.button {
    display: flex;
    align-items: center;
    font-size: 0.5vw;
    cursor: pointer;
    margin-right: 0.8vw;
}
</style>