<template>
  <div class="weather">
    <img
      v-if="weather.wea_day_img"
      :src="getImagePath(weather.wea_day_img)"
      alt
    />
    <div class="date">{{ weather.week }}</div>
    <div class="tem">{{ weather.tem }}℃</div>
    <div>{{ weather.wea }}</div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      weather: {},
    };
  },

  mounted() {
    this.getWeatherForeCast();
  },
  methods: {
    getWeatherForeCast() {
      fetch(
        "http://adm.ac.cn/Api/Weather/getForcast7?token=njszy&city=淮安"
      )
        .then((res) => res.json())
        .then((res) => {
          if (res && res.info && Array.isArray(res.info.data)) {
            this.weather = res.info.data[0] ?? {};
          }
        });
    },

    getImagePath(name) {
      return `http://adm.ac.cn/static/img/weather/mango/${name}.png`;
    },
  },
};
</script>


<style lang="less" scoped>
.weather {
  display: flex;
  align-items: center;
  font-size: 0.5vw;
  margin-right: 0.8vw;
  img {
    width: 0.6vw;
    margin-right: 0.4vw;
  }
  .date {
    margin-right: 0.4vw;
  }

  .tem {
    margin-right: 0.4vw;
  }
}
</style>
